export const BG_PRIMARY = 'rgba(157,88,45,1)';
export const BG_PRIMARY_GRADIENT = 'rgba(126,67,32,1)';
export const BG_SECONDARY = 'rgba(242,242,242,1)';

export const TEXT_PRIMARY = '#FFFFFF';
export const TEXT_SECONDARY = 'rgba(126,67,32,1)';

export const BUTTON_COLOR_TEXT = 'rgba(126,67,32,1)';

// Icon & Navigation
export const BG_NAVIGATION = `#fff`;
export const BG_NAVIGATION_ICON_COLOR = `#000`;

// DEFAULT STYLES
export const DEFAULT_BUTTON_PROPS = {
  _active: { borderColor: 'transparent' },
  _hover: { borderColor: 'transparent' },
  _focus: { borderColor: 'transparent' },
};
export const DEFAULT_OPTIONS_PROPS = {
  style: { color: 'black' },
};
